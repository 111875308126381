.App {
  text-align: center;
}

/* .App-header {
  background-color: #3A64F2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

/* #navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#navbar a {
  padding: 5px;
} */

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.hue-rotate-effect {
  transition: color 0.15s 0.15s;
}

.hue-rotate-effect:hover {
  @apply text-custom-purple;
}

.hue-rotate-effect:hover img {
  animation: flip-effect 0.3s both;
}

@keyframes flip-effect {
  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0) scale(1.15);
  }
}