@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700;900&display=swap'); */

@layer utilities {
  .-bg-top-60p {
    background-position-y: -60px;
  }

  .-bg-top-80p {
    background-position-y: -80px;
  }
  
  .border-between-horizontal {
    border-width: 1px 0px;
    border-style:  solid;
    border-color: #DFDFDF;
  }

  .border-between-horizontal:before, .border-between-horizontal:after {
    content: '';
    background: #DFDFDF;
    width: 1px;
    top: 0;
    bottom: 0;
    left: 33.3%;
    position: absolute;
  }

  .border-between-horizontal:after {
    left: 66.6%;
  }

  .border-between-horizontal p:after {
    display: none;
  }

  .border-between-vertical {
    border-width: 0px 1px;
    border-style:  solid;
    border-color: #DFDFDF;
  }

  .border-between-vertical p:not(:last-child):after {
    content: '';
    background: #DFDFDF;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 35px;
  }

  .member-separator:after {
    content: '';
    position: absolute;
    width: 1px;
    top: -50px;
    bottom: 0;
    right: -20px;
    @apply bg-custom-blue;
  }

  .member-separator-none:after {
    width: 0;
  }
}

.text-gradient-blue {
  @apply text-custom-blue;
  background: -webkit-linear-gradient(180deg, #0043FF 0%, #2E2DC4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-ring *:not(.z-0) {
  z-index: 3;
}

/* .bg-gradient-ring div:first-child {
  z-index: 1;
  border-radius: 9999px 9999px 0 0;
  filter: blur(2px);
  top: -3.1rem;
  right: -3.05rem;
  left: -3.05rem;
} */

@keyframes scale-up-out {
  0%, 100% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1);
  }
}

.bg-gradient-ring:hover:before,
.bg-gradient-ring:hover:after,
.bg-gradient-ring:hover .counter {
  animation: scale-up-out 3s infinite ease-in-out;
}

.bg-gradient-ring:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: #0043FF;
  filter: blur(25px);
  border-radius: 9999px;
  z-index: 0;
}

.bg-gradient-ring:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('gradient_ring.svg');
  background-size: contain;
  z-index: 2;
}

.bg-triangle:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('triangle_bg.svg') no-repeat center;
  background-size: contain;
}

html {
  scroll-behavior: smooth;
  @apply font-Poppins lg:font-Inter text-lg text-custom-black;
}

video {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

ul {
  list-style: square inside;
}

ul > li {
  text-indent: -22px;
  margin-left: 22px;
}

ul.custom-point, ul.custom-icon-point {
  list-style: none;
}

ul.custom-point li {
  text-indent: -32px;
  margin-left: 22px;
  margin-bottom: 15px;
}

ul.custom-point li:before {
  content: '';
  display: inline-block;
  height: 0.9em;
  width: 0.9em;
  margin-right: 0.9em;
  background: url('triangle_point.svg') no-repeat;
  background-size: contain;
  background-position: center;
}

ul.custom-icon-point li {
  text-indent: 0;
  margin-left: 22px;
  margin-bottom: 20px;
  @apply flex items-center md:text-lg;
}

ul.custom-icon-point li img {
  @apply w-16 h-16 inline-block mr-2;
}

.form-group input:focus + label, .form-group input:not(:placeholder-shown) + label,
.form-group textarea:focus + label, .form-group textarea:not(:placeholder-shown) + label {
  transform: translate(-5%, -105%) scale(0.9);
}

h1, h2, h3, h4, h5, h6 {
  @apply font-Poppins lg:font-Inter;
}

.animate-fade-in {
  animation: fade-in 4s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-in-left-1 {
  opacity: 0;
  position: absolute;
  animation: slide-in-left-1 20s 1.5s infinite linear;
}

.slide-in-left-2 {
  opacity: 0;
  position: absolute;
  animation: slide-in-left-2 20s 1.5s infinite linear;
}

.slide-in-left-3 {
  opacity: 0;
  position: absolute;
  animation: slide-in-left-3 20s 1.5s infinite linear;
}

.slide-in-right-1 {
  opacity: 0;
  position: absolute;
  animation: slide-in-right-1 20s 1.5s infinite linear;
}

.slide-in-right-2 {
  opacity: 0;
  position: absolute;
  animation: slide-in-right-2 20s 1.5s infinite linear;
}

.slide-in-right-3 {
  opacity: 0;
  position: absolute;
  animation: slide-in-right-3 20s 1.5s infinite linear;
}


@keyframes slide-in-left-1 {
  0%, 33.3%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(-150%);
  }
  5%, 28.3% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

@keyframes slide-in-left-2 {
  0%, 33.3%, 66.6%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(-150%);
  }
  38.3%, 61.6% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

@keyframes slide-in-left-3 {
  0%, 66.6%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(-150%);
  }
  71.6%, 95% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

@keyframes slide-in-right-1 {
  0%, 33.3%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(150%);
  }
  5%, 28.3% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

@keyframes slide-in-right-2 {
  0%, 33.3%, 66.6%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(150%);
  }
  38.3%, 61.6% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

@keyframes slide-in-right-3 {
  0%, 66.6%, 100% {
    opacity: 0;
    position: absolute;
    transform: translatex(150%);
  }
  71.6%, 95% {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: flex-end;
  justify-content: center;
  width: inherit;
  max-height: 65vh;
  @apply m-5;
}

.slick-slide img {
  @apply max-h-full;
}

.head-picker .picker-slide img {
  width: auto;
  margin: auto;
  @apply max-h-[90px] lg:max-h-[140px];
}

.torso-picker .picker-slide img {
  width: auto;
  margin: auto;
  @apply max-h-[180px] lg:max-h-[320px];
}

.torso-picker .slick-slide, .head-picker .slick-slide {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: auto 20px;
}

.slick-slide > div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @apply h-full;
}

.robot-display:before {
  content: '';
  @apply absolute -top-4 left-10 right-10 sm:left-0 sm:right-0 bottom-8 md:-left-4 md:-right-4 md:top-0 rounded-[45px] border border-[#778AFF];
}

.robot-display .slick-slide img {
  max-height: 100%;
  max-width: 50%;
  height: auto;
}

.robot-display .slick-track .slick-slide {
  margin: 0;
  height: 100%;
  max-height: none;
}

.team-slider .slick-slide {
  @apply max-h-52 m-2;
}

.team-slider .slick-slide > div {
  @apply max-h-52 max-w-[13rem];
}

.team-slider .slick-slide img {
  @apply max-h-52 w-auto;
}

.team-slider.slider.regular {
  @apply flex flex-row flex-wrap gap-3 max-md:gap-y-5 md:gap-6 col-start-2 col-end-2 flex-shrink flex-grow basis-auto justify-center lg:justify-start self-start items-start;
}

.team-slider.slider.inverted.regular {
  @apply col-start-1 col-end-1;
}

.no-slick.slider.regular {
  @apply flex flex-row gap-3 md:gap-6 flex-wrap flex-shrink flex-grow basis-auto justify-center self-center items-start;
}
.bounce-down {
  animation: bounce-down 0.3s ease;
}

@keyframes bounce-down {
  0% {
    transform: translateY(-100%);
  }
  90% {
    transform: translateY(10%);
  }
  100% {
    transform: none;
  }
}

.bounce-up {
  animation: bounce-up 0.3s ease;
}

@keyframes bounce-up {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(10%);
  }
  90% {
    transform: translateY(-100%);
  }
  100% {
    transform: none;
  }
}

.rotate-on-hover {
  animation: rotate-clockwise 25s infinite linear;
}

.rotate-on-hover .counter {
  animation: rotate-counterclockwise 25s infinite linear;
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-counterclockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.loader img {
  animation: logo-animation 2s ease 1.5s both;
}

.loader h1 {
  animation: fade-out 2s ease 1.5s both;
}

.loader {
  animation: bg-fade-out 2s ease 1.5s both;
}

@keyframes bg-fade-out {
  from {
    background-color: white;
  } to {
    background-color: transparent;
  }
}

@keyframes logo-animation {
  from {
    height: 8rem;
    position: fixed;
    max-width: 80%;
    top: 35vh;
    left: 50%;
    transform: translateX(-50%);
    /* @apply h-32 absolute max-w-[80%] top-[35vh] left-1/2 -translate-x-1/2; */
  }
  to {
    height: 2.5rem;
    position: fixed;
    max-width: 60%;
    top: 0.5rem;
    left: 1.25rem;
    transform: translateX(0);
    /* @apply h-10 max-w-[60%] left-5 absolute top-2 translate-x-0; */
  }
}

@media (min-width: 768px) {
  @keyframes logo-animation {
    from {
      height: 8rem;
      position: fixed;
      max-width: 80%;
      top: 35vh;
      left: 50%;
      transform: translateX(-50%);
    /* @apply h-32 absolute max-w-[80%] top-[35vh] left-1/2 -translate-x-1/2; */
      /* @apply h-32 absolute max-w-[80%] top-[35vh] left-1/2 -translate-x-1/2; */
    }
    to {
      height: 2.5rem;
      position: fixed;
      max-width: 60%;
      top: 0.5rem;
      left: 5rem;
      transform: translateX(0);
      /* @apply h-10 max-w-[60%] left-20 absolute top-2 translate-x-0; */
    }
  }
}

.text-gradient {
  background: -webkit-linear-gradient(180deg, #F09246 -24.2%, #EA414B 8.52%, #E030CC 58.11%, #5B6BF0 105.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}